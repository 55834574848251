import { render } from "./TopNav.vue?vue&type=template&id=7d5e6b33"
import script from "./TopNav.vue?vue&type=script&lang=ts"
export * from "./TopNav.vue?vue&type=script&lang=ts"

import "./TopNav.vue?vue&type=style&index=0&id=7d5e6b33&lang=scss"
script.render = render

export default script
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QHeader,QToolbar,QBtn,QToolbarTitle,QSpace,QSeparator});
