
import { defineComponent } from 'vue';
import { useStore as useLeftDrawerStore } from '@/store/leftDrawer';
import LoginLogout from '@/components/LoginLogout.vue';
import { Settings } from '@/settings';

export default defineComponent({
  components: {
    LoginLogout
  },
  setup () {
    const leftDrawerStore = useLeftDrawerStore();

    return {
      toggleLeftDrawer: leftDrawerStore.toggleLeftDrawer,
      Settings
    };
  }
});
