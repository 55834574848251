import { render } from "./LoginLogout.vue?vue&type=template&id=6b9d8424"
import script from "./LoginLogout.vue?vue&type=script&lang=ts"
export * from "./LoginLogout.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QItem,QItemSection,QItemLabel});
