<template>
    <q-header elevated class="o-topNav bg-pl-white text-pl-navy-900">
      <q-toolbar class="o-topNav__toolbar">
        <q-btn
          flat
          dense
          round
          @click="toggleLeftDrawer"
          aria-label="Menu"
          icon="mdi-menu"
          class="o-topNav__toolbar__item -drawerToggle"
        />

        <q-toolbar-title class="o-topNav__toolbar__title">
          Cloud Services Gateway
        </q-toolbar-title>
        <q-space />
        <q-separator vertical inset />
        <div class="o-topNav__toolbar__item -version">
          v{{ Settings.appVersion }}
        </div>
        <q-separator vertical inset />
        <LoginLogout class="o-topNav__toolbar__item -logout"/>
      </q-toolbar>
    </q-header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore as useLeftDrawerStore } from '@/store/leftDrawer';
import LoginLogout from '@/components/LoginLogout.vue';
import { Settings } from '@/settings';

export default defineComponent({
  components: {
    LoginLogout
  },
  setup () {
    const leftDrawerStore = useLeftDrawerStore();

    return {
      toggleLeftDrawer: leftDrawerStore.toggleLeftDrawer,
      Settings
    };
  }
});
</script>

<style lang="scss">
.o-topNav {
    &__toolbar {
        margin: 0;
        min-height: 64px;
        max-height: 64px;
        padding: 0;

        &__item {
            padding: 0 16px;
        }
    }
}
</style>
