<template>
  <q-drawer
      v-model="drawerState"
      side="left"
      bordered="bordered"
      class="o-left-drawer bg-pl-navy-900"
      :width="256"
    >
      <div class="o-left-drawer__header">
        <img
          class="o-left-drawer__header__avatar"
          src="@/assets/penlink-logo-white.png"/>
      </div>
      <q-list>
        <q-item
          class="o-left-drawer__item -subscriptions text-pl-white-3"
          id="nav-subscriptions"
          :to="{ name: SubscriptionRoutes.list }">
          <q-item-section avatar="avatar">
            <q-icon name="mdi-key-outline"/>
          </q-item-section>
          <q-item-section>Subscriptions</q-item-section>
        </q-item>
        <q-item
          class="o-left-drawer__item -image-categories text-pl-white-3"
          id="nav-image-categories"
          :to="{ name: ImageCategoryRoutes.list }">
          <q-item-section avatar="avatar">
            <q-icon name="mdi-image"/>
          </q-item-section>
          <q-item-section>Image Categories</q-item-section>
        </q-item>
        <q-item
          class="o-left-drawer__item -image-analysis text-pl-white-3"
          id="nav-image-analysis"
          :to="{ name: ApiTestRoutes.imageAnalysis }"
        >
          <q-item-section avatar="avatar">
            <q-icon name="mdi-eye"/>
          </q-item-section>
          <q-item-section>Image Analysis</q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useStore as useLeftDrawerStore } from '@/store/leftDrawer';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import { ImageCategoryRoutes } from '@/router/routes/imageCategories';
import { ApiTestRoutes } from '@/router/routes/apiTest';

export default defineComponent({
  setup () {
    const leftDrawerStore = useLeftDrawerStore();

    const drawerState = computed({
      get: () => leftDrawerStore.state.open,
      set: (value) => leftDrawerStore.setLeftDrawerState(value)
    });

    return {
      drawerState,
      SubscriptionRoutes,
      ImageCategoryRoutes,
      ApiTestRoutes
    };
  }
});
</script>

<style lang="scss">

.o-left-drawer {
    &__header {
        padding: 16px 52px;
        display: flex;
        height: 60px;
    }

    &__item {
        $this: &;

        min-height: 52px;

        &.-new-btn {
            padding-bottom: 24px;
        }

        &:not(.-new-btn) {
            padding-left: 48px;
        }

        &.q-router-link--active {
            $active-link-color: color-lightness($color-blue-400, '-3');
            background: $active-link-color !important;
            color: $color-white !important;
        }
    }

    &__separator {
        &:after {
            content: '';
            display: block;
            width: 85%;
            margin: 0 0 0 auto;
            border-bottom: 2px solid color-lightness($color-white, '-3');
        }
    }
}
</style>
