import { render } from "./LeftDrawer.vue?vue&type=template&id=2313d5aa"
import script from "./LeftDrawer.vue?vue&type=script&lang=ts"
export * from "./LeftDrawer.vue?vue&type=script&lang=ts"

import "./LeftDrawer.vue?vue&type=style&index=0&id=2313d5aa&lang=scss"
script.render = render

export default script
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QList,QItem,QItemSection,QIcon});
