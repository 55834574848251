
import { defineComponent, computed } from 'vue';
import { useStore as useLeftDrawerStore } from '@/store/leftDrawer';
import { SubscriptionRoutes } from '@/router/routes/subscriptions';
import { ImageCategoryRoutes } from '@/router/routes/imageCategories';
import { ApiTestRoutes } from '@/router/routes/apiTest';

export default defineComponent({
  setup () {
    const leftDrawerStore = useLeftDrawerStore();

    const drawerState = computed({
      get: () => leftDrawerStore.state.open,
      set: (value) => leftDrawerStore.setLeftDrawerState(value)
    });

    return {
      drawerState,
      SubscriptionRoutes,
      ImageCategoryRoutes,
      ApiTestRoutes
    };
  }
});
