
import { defineComponent } from 'vue';
import { useStore as useLoginStore } from '@/store/login';

export default defineComponent({
  setup () {
    const loginStore = useLoginStore();

    return {
      async signOut () {
        await loginStore.signOut();
      }
    };
  }
});
