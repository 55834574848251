<template>
    <q-item clickable @click="signOut">
      <q-item-section>
          <q-item-label>Sign Out</q-item-label>
      </q-item-section>
    </q-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore as useLoginStore } from '@/store/login';

export default defineComponent({
  setup () {
    const loginStore = useLoginStore();

    return {
      async signOut () {
        await loginStore.signOut();
      }
    };
  }
});
</script>
